.main {
    width: 800px;
    height: 600px;
    text-align: center;
    justify-content: center;
}
.btn {
    width: 200px;
    margin: 0 auto 1rem auto !important;
}
.img {
    width: 80%;
    margin: 0 auto;
}
.heading {
    color: #566a7f;
    margin-bottom: 1rem !important;
}
.sub_heading {
    color: #697a8d;
    margin-bottom: 1rem !important;
}

@media only screen and (max-width: 600px) {
    .heading {
        font-size: 1.5rem !important;
    }
    .sub_heading {
        font-size: 0.75rem !important;
    }
}